/*@import url(//fonts.googleapis.com/css?family=Open+Sans:300italic,400italic,700italic,400,700,300,100);*/
@import "font-awesome/font-awesome.less";
@import "bootstrap/bootstrap.less";
@import "inspinia/style.less";
@import "../../users/less/style.less";
@import "../../files/less/style.less";
@import "../../comments/less/style.less";
@import (inline) "../static/css/animate.min.css";
@import (inline) "/node_modules/adm-dtp/dist/ADM-dateTimePicker.css";

// Variables
@font-size-base: 13px;
@font-size-large: @font-size-base * 2;
@font-family-base: 'Open Sans', 'Helvetica-Neue', 'Helvetica', 'Trebuchet MS', 'Arial', sans-serif;
@margin-default: 20px;
@padding-default: 20px;
@gray-lightest: lighten(@gray-lighter, 5%);
@highlight-color: lighten(@brand-success, 20%);

@success-color: #2ecc71;
@info-color: #3498db;
@danger-color: #e74c3c;
@warning-color: #e67e22;
@invoiced-color: #b3b7b1;
@navbar-color: #2D2D2D;
@navbar-active: #FF7D00;

// my styles
@import "list-view.less";
@import "typeahead.less";
@import "forms.less";
@import "bootstrap-tricks.less";
@import "wizard.less";
@import "sortable-list.less";

// General HTML tags styles
body {
  background: @gray-lighter;
}

// ngView Wrapper
.ng-view-wrapper {
}

// sections titles
.section-title {
  padding: 0;
  margin: 0 0 @margin-default 0;
  font-size: @font-size-large;

  .label {
    margin-top: -5px;
    display: inline-block;
    vertical-align: middle;
  }
}

.list-view .list-view-controls {
  padding: 10px;
}

.list-view-filters-wrapper {
  border-radius: 4px;
  background: white;
  padding: 10px 10px 1px 10px;
}

.list-view .list-view-body .list-view-item {
  color: #676a6c;
  border-left: 3px solid #ffffff;
  border-bottom: 1px solid #e6e6e6;
  background-color: #ffffff;
  padding: 16px;
}

// Form Validation
.error-validation {
  color: #d9534f;
  margin-bottom: 10px;
}

// Hide spinner buttons input type 'number'
input::-webkit-outer-spin-button, input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  margin: 0;
}

// SC repor table
.table-sc-report td {
  transition: background-color 0.25s;
}

.checkbox-subtitle {
  margin-left: 30px;
}

input[type="radio"], input[type="checkbox"] {
  margin-right: 19px;
  margin-top: 1px;
  line-height: normal;
}

.download-right {
  position: absolute;
  right: 15px;
}

// Status bar

.status-bar {
  text-align: center;
  padding: 7px;
  position: fixed;
  opacity: 1;
  top: 0px;
  left: 0px;
  right: 0px;
  color: #ffffff;
  z-index: 200001;

  & span {
    cursor: pointer;
    float: right;
  }
}

.success-alert {
  background-color: @success-color;
}

.info-alert {
  background-color: @info-color;
}

.warning-alert {
  background-color: @warning-color;
}

.danger-alert {
  background-color: @danger-color;
}

.status-bar.ng-hide-add-active,
.status-bar.ng-hide-remove-active {
  transition: all 0.25s linear;
}

.status-bar.ng-hide {
  opacity: 0;
}

// List deletion Button styles
.deletionBtn:hover {
  background-color: #fff;
}

.rightRadiusBorder {
  border-top: 0px solid transparent;
  border-bottom-border: 1px solid transparent;
  border-bottom-right: 1px solid transparent;
  border-bottom-right-radius: 4px;
  border-top-right-radius: 4px;
}

.deletionButtonAlignment {
  height: 30px;
  padding-top: 4px;
}

.heigth32 {
  height: 32px;
}

.deletionButtonRow {
  height: 31px;
  padding-right: 3px
}

.noTopBottomPadding {
  padding-top: 0px;
  padding-bottom: 0px;
}

// Dropdown contract list
.nestedLi {
  display: block;
  padding: 3px 20px;
  clear: both;
  font-weight: 400;
  line-height: 1.42857143;
  color: #333;
  white-space: nowrap;
}

.nodecorate:hover {
  text-decoration: none;
}

.contract-creation-paste {
  margin-bottom: 10px;
}

.tab-pane .tab-content {
  border: none;
  padding: 0px;
}

// Product list
.form-product-edit-code {
  display: block;
  width: 100%;
  height: 32px;
  padding: 6px 12px;
  font-size: 13px;
}

.invoice-line-data {
  padding-right: 2px;
  padding-left: 2px;
  padding-top: 2px;
}

.invoice-total-panel {
  position: fixed;
  z-index: 1000;
  bottom: 0;
  padding: 5px !important;
}

.navbar-powr {
  position: fixed !important;
  background-color: @navbar-color;
  min-height: 100%;
}

.navbar-powr li.active {
  border-left: 4px solid @navbar-active !important;
  background: rgba(147, 150, 149, 0.09) !important;
}

.btn-primary-powr, .btn-primary-powr:hover, .btn-primary-powr:focus, .btn-primary-powr:active, .btn-primary-powr.active {
  background-color: @navbar-active !important;
  border-color: @navbar-active !important;;
}

.navbar-default .nav > li > a:hover, .navbar-default .nav > li > a:focus {
  background-color: rgba(93, 93, 93, 0.23);
}

.mini-navbar .nav-second-level {
  background-color: #363736 !important;
}

.nav.navbar-right > li > a {
  color: @navbar-color !important;
}

#page-wrapper {
  padding: 0px;
}

@media (min-width: 1200px) {
  .container {
    width: auto !important;
  }
}

@media (min-width: 992px) {
  .container {
    width: auto !important;
  }
}

@media (min-width: 768px) {
  .container {
    width: auto !important;
  }
}

.nav-header {
  background: none !important;
}

.btn {
  &.active {
    color: white;
    background-color: @navy;
    border-color: @navy;
    box-shadow: 0 0 0;
  }
}

.nav-tabs li {
  a {
    color: #666;
  }

  &.active a {
    background-color: white !important;
    border-bottom-color: white !important;;
  }
}

.text-green-success {
  color: #19aa8d;
}

.dropdown-menu {
  min-width: 70px;
}

.scrollable-typeahead .dropdown-menu {
  min-width: 70px;
  max-height: 200px;
  overflow: auto;
}

.dropdown-menu .start-divider {
  margin-top: 8px;
}

.dropdown-menu .end-divider {
  margin-bottom: 8px;
}

.disabled-bg {
  background-color: #eee;
}

.overflow_list_wrapper {
  width: 100%;
  height: 300px;
  overflow: auto;
}

.row-eq-height {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
}

.deferred-invoice-item {
  color: #676a6c;
  border-left: 3px solid #ffffff;
  border-bottom: 1px solid #e6e6e6;
  background-color: #ffffff;
  padding: 12px;
}

.table-margin {
  margin-left: 15px;
  margin-right: 15px;
}

.control-label {
  text-align: left !important;
}

.scrollable-x {
  overflow-x: scroll;
}

.sips-consumptions-table {
  min-width: 2400px;
  table-layout: fixed;
}

.form-control-input {
  height: 30px !important;
}

.active_remtable {
  color: white !important;
  background-color: #1ab394 !important;
  border-color: #1ab394 !important;
  box-shadow: 0 0 0 !important;
}

.uploader.btn-group {
  margin-bottom: 10px;
  .caret {
    margin: 6px;
  }
  .badage {
    color: #555555;
  }
  .dropdown-menu {
    background-color: #F2F3F2;
    border-color: #000000;
    color: #555;
    width: 100%;
  }
  .dropdown-menu a:hover {
    background-color: #555;
    color: #F2F3F2;
  }
}

.version {
  right: 0;
  bottom: 0;
  padding: 5px 20px;
  position: fixed;
  color: #000;
  font-size: 11px;
  opacity: 50%;
}

.powr-scrolleable {
  max-height: 70vh !important;
}

.fix-top-heading {
  margin-top: -15px;
}

.ADMdtp-box{
  z-index: 3000;
}


.ADMdtpInput.disable input {
  background-color: #eee;
}


.invoice-readings {
  .ADMdtpInput input {
    border-radius: 0px;
    height: 32px;
  }
  .ADMdtpInput .ng-touched.ng-valid {
    border-color: #1ab394;
  }
  .ADMdtpInput .ng-touched.ng-invalid {
    border-color: #ed5565;
  }
}

.disclaimer {
  background-color: deepskyblue;
  color: white;
  padding: 12px;
  border-radius: 5px;
  font-weight: bold;
  display: inline-block;
  margin: 0 auto;
  position: absolute;
  top: 10px;
  left: 300px;
  z-index: 200000;
  a {
    text-decoration: underline;
  }
}

.mini-navbar .disclaimer {
  left: 150px;
}

.form-item {
  margin-bottom: 15px;
}

.cb-strict-dates {
  display: flex; 
  align-items: center; 
  justify-content: flex-start;
}


.fullscreen-modal{
  background: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1000;
  width: 100%;
  height: 100%;
}

.adm-small{
  position: relative;
}

.adm-small input{
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  border-bottom-right-radius: 4px;
  border-bottom-left-radius: 4px
}

.adm-small .dtp-ig{
  display: None
}

.adm-small .removeIcon{
  right: 0
} 